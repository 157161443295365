import { ITheme } from "@styles/theme";
import { mqHiDpi } from "@uxf/styles/responsive/mq-hidpi";
import { css } from "styled-components";

export const videoPoster = (theme: ITheme) => css`
    background: ${theme.color.palette.bg} url("/static/images/landingPage/vote-screenshot.png") no-repeat center top /
        cover;

    ${mqHiDpi()} {
        background-image: url("/static/images/landingPage/vote-screenshot@2x.png");
    }
`;
