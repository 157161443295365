import { IconsType } from "@config/icons";
import { Box } from "@ui/Box";
import { Icon } from "@ui/Icon";
import { TextH4, TextLarge } from "@ui/Text";
import { FC, ReactNode } from "react";

interface IProps {
    title: ReactNode;
    text: ReactNode;
    icon: IconsType;
    large?: boolean;
    inProgress?: boolean;
}

export const FeaturesGridItem: FC<IProps> = (props) => {
    const { icon, inProgress, large = false, text, title } = props;

    return (
        <Box>
            <Icon
                name={icon}
                iconWidth={large ? [32, null, null, 44] : 32}
                iconHeight={large ? [32, null, null, 44] : 32}
                size={24}
                color={inProgress ? "textMuted" : "primary"}
                mb={16}
            />
            <TextH4
                as={large ? "h2" : "h4"}
                textColor={inProgress ? "textMuted" : "text"}
                mb={large ? [8, null, null, 24] : 8}
                textVariant={["h4", null, null, large ? "h3" : null]}
            >
                {title}
            </TextH4>
            <TextLarge mb={20} textColor={inProgress ? "textMuted" : "text"} as="p" textFontWeight="regular">
                {text}
            </TextLarge>
        </Box>
    );
};
