import { useTranslation } from "@translations/use-translation";
import { FlexBox } from "@ui/FlexBox";
import { Image } from "@ui/Image";
import { Property } from "csstype";
import { FC } from "react";
import { useTheme } from "styled-components";

interface IProps {
    imageSrc: string;
    imgMaxHeight: Property.MaxHeight<number> | null | Array<Property.MaxHeight<number> | null>;
}

export const VoteBenefitScreenshot: FC<IProps> = (props) => {
    const theme = useTheme();
    const { imageSrc } = props;
    const { t } = useTranslation();
    return (
        <FlexBox childrenFlexProps={{ maxWidth: [420, null, 480] }} maxWidth={[420, null, 480]}>
            <Image
                boxShadow={theme.shadow.dropdown}
                imgProps={{
                    alt: t("component-landing-vote-benefit-screenshot:voting-show"),
                    role: "presentation",
                    title: t("component-landing-vote-benefit-screenshot:voting-show"),
                }}
                objectFit="cover"
                src={imageSrc}
                aspectRatio={2610 / 1740}
            />
        </FlexBox>
    );
};
