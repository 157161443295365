import { Box } from "@ui/Box";
import { Text, TextLarge } from "@ui/Text";
import { CssHelper } from "@utils/CssHelper";
import { FC, ReactNode } from "react";

interface IProps {
    heading: ReactNode;
    text: ReactNode;
    title: ReactNode;
}

export const VoteBenefitBox: FC<IProps> = (props) => {
    const { heading, text, title } = props;

    return (
        <Box>
            <TextLarge mb={CssHelper.spacing(1.5)} textColor="primary">
                {heading}
            </TextLarge>
            <Text as="h2" textVariant={["h4", null, "h2"]} textFontWeight="medium" mb={CssHelper.spacing(2.5)}>
                {title}
            </Text>
            <TextLarge textColor="textMuted">{text}</TextLarge>
        </Box>
    );
};
